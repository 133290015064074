<template>
  <div>
    <p class="text-center style-text-p">Cadastro de Chamado</p>
    <vuestic-widget>
      <p class="style-box">Chamada</p>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="novoChamado.nome"
                  id="simple-input-name"
                >
                <label
                  for="simple-input-name"
                  class="control-label"
                >Nome do Funcionario</label>
                <span
                  class="alert-danger"
                  v-if="erros.nome"
                >{{erros['nome'][0]}}</span>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="select-control-label">Setor</label>
            <multiselect
              v-model="setorSelecionado"
              placeholder="Selecione"
              track-by="id"
              label="nome"
              :options="setores"
              selectedLabel="Selecionado"
              select-label="Selecione um setor"
              deselect-label="Remover setor"
              :loading="loading"
            >
              <span slot="noResult">Setor não encontrado.</span>
              <template slot="noOptions">Sem setores</template>
            </multiselect>
            <span
              class="alert-danger"
              v-if="erros.setor"
            >{{erros['setor'][0]}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="novoChamado.titulo"
                  id="simple-input-title"
                >
                <label
                  for="simple-input-title"
                  class="control-label"
                >Titulo</label>
                <span
                  class="alert-danger"
                  v-if="erros.titulo"
                >{{erros['titulo'][0]}}</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <input
                  type="date"
                  v-model="novoChamado.prazo"
                  id="simple-input-prazo"
                  required
                >
                <label
                  class="select-control-label"
                  for="simple-input-prazo"
                >Prazo</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <textarea
                  v-model="novoChamado.chamado"
                  id="simple-input-call"
                />
                <label
                  for="simple-input-call"
                  class="control-label"
                >Chamado</label>
                <span
                  class="alert-danger"
                  v-if="erros.chamado"
                >{{erros['chamado'][0]}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button
                class="btn btn-pale btn-micro mr-2"
                @click="clearForm()"
              >Limpar Campos
              </button>
              <button
                class="btn btn-primary btn-micro"
                @click="cadastrarChamado"
              >Confirmar
              </button>
          </div>
        </div>
      </div>

    </vuestic-widget>
  </div>

</template>

<script>
export default {
  props: {
    idEmpresa: { requered: true },
    chamado: { type: Object },
  },
  data() {
    return {
      novoChamado: {
        situacao: 1,
        nome: '',
        titulo: '',
        chamado: '',
        prazo: '',
        setor: null,
        empresa: null,
      },
      setorSelecionado: null,
      setores: [],
      erros: {},
      loading: true,
    };
  },
  created() {
    this.novoChamado.empresa = this.idEmpresa;
    this.getEmpresa();
  },
  methods: {
    getSetores(listIdAreas) {
      this.$axios.get('/api/area-list/').then((res) => {
        this.loading = false;
        this.setores = (res.data)
          .filter(area => listIdAreas.findIndex(el => el === area.id) !== -1);
      }).catch(() => {
        this.loading = false;
      });
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.idEmpresa}/`).then((res) => {
        this.getSetores(res.data.areas);
      }).catch(() => {
        this.loading = false;
      });
    },
    cadastrarChamado() {
      this.$axios.post('/api/chamado/', this.novoChamado).then((res) => {
        this.$emit('success', res.data);
        this.clearForm();
        this.message('success', 'Chamado cadastrado com sucesso!!!');
      }).catch((error) => {
        this.erros = error.response.data;
        console.error(this.erros);
        this.message('error', 'Erro ao cadastar!!!');
      });
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    clearForm() {
      this.novoChamado = {
        situacao: 1,
        nome: '',
        titulo: '',
        chamado: '',
        prazo: '',
        setor: null,
        empresa: this.idEmpresa,
      };
      this.setorSelecionado = null;
      this.erros = {};
    },
  },
  watch: {
    setorSelecionado(val) {
      this.novoChamado.setor = val ? val.id : null;
    },
  },
};
</script>

<style scoped>
.select-control-label {
  left: 22px;
}
</style>
