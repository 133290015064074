<template>
  <div>
    <chamado
      :idEmpresa="empresa"
      v-on:success="abrirModalConfirmacao"
    />
    <modal-acao
      ref="modalAcao"
      :acao="acao"
    />
    <vuestic-modal
      ref="modalConfirmacao"
      v-on:ok="abrirAcao"
    >
      <div slot="title">Ação</div>
      <div><b>Você deseja criar um plano de ação</b></div>
    </vuestic-modal>
  </div>
</template>

<script>
import chamado from '@/components/my-components/form/chamado.vue';
import modalAcao from '@/components/user/form/modal/acao.vue';

export default {
  components: {
    chamado,
    modalAcao,
  },
  data() {
    return {
      empresa: localStorage.empresaSelecionada,
      acao: {
        chamado: [],
        ferramenta: {
          id: 13,
          ferramenta: 'Chamado',
        },
      },
    };
  },
  methods: {
    abrirModalConfirmacao(acao) {
      this.$refs.modalConfirmacao.open();
      this.acao.chamado = [acao.id];
    },
    abrirAcao() {
      this.$refs.modalAcao.open();
    },

  },
};
</script>

<style>
</style>
